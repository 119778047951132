var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-bottom-sheet',{attrs:{"width":"50%"},model:{value:(_vm.computedIsVisible),callback:function ($$v) {_vm.computedIsVisible=$$v},expression:"computedIsVisible"}},[_c('div',{staticClass:"d-flex flex-column justify-space-around pa-2"},[_c('div',{staticClass:"d-flex my-3"},[_c('v-card',{staticClass:"card flex-grow-1"},[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.$t('t.Display')))])]),_c('v-card-text',[_c('two-state-btn-toggle',{attrs:{"items":[
            {label: _vm.$t('t.Light'), icon: 'i.Sun', value: false},
            {label: _vm.$t('t.Dark') , icon: 'i.Moon', value: true}
            ],"value":_vm.themeController.isDark},on:{"update:value":function($event){return _vm.$set(_vm.themeController, "isDark", $event)}}}),_c('v-switch',{attrs:{"label":_vm.$t('t.Framed')},model:{value:(_vm.isFramed),callback:function ($$v) {_vm.isFramed=$$v},expression:"isFramed"}})],1)],1),_c('v-card',{staticClass:"card flex-grow-1"},[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.$t('t.Color')))])]),_c('v-card-text',_vm._l((_vm.themeController.customizableColors),function(v,name){return _c('v-menu',{key:name,attrs:{"offset-y":"","top":"","nudge-top":10,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"my-1",class:name,attrs:{"width":"100%"}},on),[_vm._v(" "+_vm._s(_vm.$t(("t." + (_vm.toPascalCase(name)))))+" ")])]}}],null,true)},[_c('v-color-picker',{attrs:{"mode":"hexa","value":_vm.themeController[name] || v,"show-swatches":""},on:{"update:color":function($event){return _vm.selectColor(name, $event)}}})],1)}),1)],1),_c('v-card',{staticClass:"card flex-grow-1"},[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.$t('t.BorderRadius')))])]),_c('v-card-text',[_c('two-state-btn-toggle',{attrs:{"items":[
            {label: _vm.$t('t.Sharp'), icon: 'i.SharpBorder', value: _vm.themeController.borderRadiusSettings.minBorderRadius},
            {label:  _vm.$t('t.Smooth') , icon: 'i.SmoothBorder', value: _vm.themeController.borderRadiusSettings.maxBorderRadius}
            ],"value":_vm.themeController.borderRadius},on:{"update:value":function($event){return _vm.$set(_vm.themeController, "borderRadius", $event)}}})],1)],1)],1),_c('v-spacer'),_c('div',{staticClass:"d-flex flex-column"},[_c('v-card',{staticClass:"d-flex flex-column flex-grow-1"},[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.$t('t.BackgroundImage')))])]),_c('v-card-text',[_c('v-text-field',{staticClass:"pa-2",attrs:{"label":_vm.$t('t.Image'),"clearable":"","hide-details":"","dense":""},model:{value:(_vm.themeController.backgroundImageUrl),callback:function ($$v) {_vm.$set(_vm.themeController, "backgroundImageUrl", $$v)},expression:"themeController.backgroundImageUrl"}})],1)],1),_c('div',{staticClass:"d-flex flex-column align-center mt-1"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.resetUiSettings()}}},[_vm._v(" "+_vm._s(_vm.$t('t.Reset'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(_vm._s(_vm.$icon('i.Restart')))])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }