<template>
  <v-bottom-sheet
    v-model="computedIsVisible"
    width='50%'
  >
    <div class="d-flex flex-column justify-space-around pa-2">
      <div class="d-flex my-3">
        <v-card class="card flex-grow-1">
          <v-card-title>
            <span>{{$t('t.Display')}}</span>
          </v-card-title>
          <v-card-text>
            <two-state-btn-toggle
              :items="[
              {label: $t('t.Light'), icon: 'i.Sun', value: false},
              {label: $t('t.Dark') , icon: 'i.Moon', value: true}
              ]"
              :value.sync="themeController.isDark"
            />
            <v-switch
              v-model="isFramed"
              :label="$t('t.Framed')"
            />
          </v-card-text>
        </v-card>
        <v-card class="card flex-grow-1">
          <v-card-title>
            <span>{{$t('t.Color')}}</span>
          </v-card-title>
          <v-card-text>
            <v-menu
              offset-y
              top
              :nudge-top=10
              v-for="(v, name) in themeController.customizableColors"
              :close-on-content-click="false"
              :key="name"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  :class="name"
                  v-on="on"
                  class="my-1"
                  width='100%'
                >
                  {{$t(`t.${toPascalCase(name)}`) }}
                </v-btn>
              </template>
              <v-color-picker
                mode="hexa"
                :value="themeController[name] || v"
                @update:color="selectColor(name, $event)"
                show-swatches
              ></v-color-picker>
            </v-menu>
          </v-card-text>
        </v-card>
        <v-card class="card flex-grow-1">
          <v-card-title>
            <span>{{$t('t.BorderRadius')}}</span>
          </v-card-title>
          <v-card-text>
            <two-state-btn-toggle
              :items="[
              {label: $t('t.Sharp'), icon: 'i.SharpBorder', value: themeController.borderRadiusSettings.minBorderRadius},
              {label:  $t('t.Smooth') , icon: 'i.SmoothBorder', value: themeController.borderRadiusSettings.maxBorderRadius}
              ]"
              :value.sync="themeController.borderRadius"
            />
          </v-card-text>
        </v-card>
      </div>
      <v-spacer />
      <div class="d-flex flex-column">
        <v-card class="d-flex flex-column flex-grow-1">
          <v-card-title>
            <span>{{$t('t.BackgroundImage')}}</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="themeController.backgroundImageUrl"
              :label="$t('t.Image')"
              clearable
              class="pa-2"
              hide-details
              dense
            />
          </v-card-text>
        </v-card>
        <div class="d-flex flex-column align-center mt-1">
          <!-- @click="displayResetModal=true" -->
          <v-btn
            @click="resetUiSettings()"
            text
            color="primary"
          >
            {{$t('t.Reset')}}
            <v-icon right>{{$icon('i.Restart')}}</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </v-bottom-sheet>
</template>

<script>
import themeController from '@/themeController'

export default {
  components: {
    TwoStateBtnToggle: () => import('@/components/two-state-btn-toggle')
  },
  activated () {
    this.$store.commit('setModule', { name: 'Application settings' })
  },
  computed: {
    computedIsVisible: {
      get () {
        return this.isVisible
      },
      set (v) {
        this.$emit('update:isVisible', v)
      }
    },
    isFramed: {
      get () {
        return themeController.isFramed
      },
      set (v) {
        themeController.isFramed = v
      }
    }
  },
  data () {
    return {
      displayMode: 0,
      themeController: themeController,
      displayResetModal: false
    }
  },
  methods: {
    selectColor (colorName, value) {
      this.themeController[colorName] = value.hex
    },
    async resetUiSettings () {
      this.displayResetModal = false
      this.themeController.resetUiSettings()
    }
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="stylus" scoped>
.card
  flex-basis 0%

.main-content, .v-card
  margin 0.5em
</style>
